import requests from '../requests';

const order = {
    details: id => requests.get(`/orders/${id}`),
    saveProduct: order => requests.post('/orders/saveproduct', order),
    confirm: order => requests.post('/orders/confirm', order),
    cancel: order => requests.post('/orders/cancel', order),
    saveStore: store => requests.put('/orders/savestore', store),
    removeProduct: product => requests.del('/orders/removeproduct', product),
    addDirectOrder: order => requests.post('/orders/save', order),
    update: (orderId, products) => requests.put('/orders/update', { orderId, products }, {errorHandle: false}),
    confirmWithExistingAccount: order => requests.post('/orders/confirm', order,{errorHandle: false}),
};

export default order;