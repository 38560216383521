import axios from 'axios';
import store from '@/store/index';
import generateSignature from './signatureService';

const baseUrl = process.env.VUE_APP_API_URL;
const apiKey = process.env.VUE_APP_API_KEY;
const secretKey = process.env.VUE_APP_SECRET_KEY;

const timeHttp = axios.create({ baseURL: baseUrl });
const axiosInstance = axios.create({ baseURL: baseUrl });

axiosInstance.interceptors.request.use(
    async config => {
        store.commit('SET_IS_LOADING', true);
        try {
            const res = await timeHttp.get('/timestamp');
            const timeStamp = res.data.timeStamp;

            const token = localStorage.getItem('token');
            const key = generateSignature(apiKey, timeStamp, secretKey);
            
            config.headers = {
                'Content-Type': 'application/json',
                'Api-Key': apiKey,
                Signature: key, 
                Authorization: 'Bearer ' + token,
                TimeStamp: timeStamp
            };
        } catch (error) {
            console.error(error);
        }               
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    response => {
        // Any response with status code within range of 2xx
        store.commit('SET_IS_LOADING', false);
        return response;
    },

    error => {
        // Any response with status code outside range of 2xx
        store.commit('SET_IS_LOADING', false);

        if ('errorHandle' in error.config && error.config.errorHandle === false) {
            return Promise.reject(error);
        }

        let errorTitle = 'Request failed';
        let errorText = 'An error occured. Please try again or contact support.'
            
        if (store.getters.requestFailed) {
            errorTitle = store.getters.requestFailed;
        }
            
        if (store.getters.anErrorOccured) {
            errorText = store.getters.anErrorOccured;
        }

        const getErrorTranslation = (label) => {
            const texts = store.state.texts.texts;
            if (texts) {
                const translations = texts.find(text => text.label === label);
                return translations[`language${store.getters.selectedLanguage}`];
            }
            return null;
        };

        if (error.response) {
            const response = error.response;
            console.error(response);
                
            if ('data' in response) {
                const translatedError = getErrorTranslation(response.data.detail);
                if (translatedError) {
                    errorText = translatedError;
                }
            }
        } else {
            console.error(error); 
        }

        store.commit('SET_ERRORS', {
            showErrorWarning: true,
            errorTitle: errorTitle,
            errorMessage: errorText
        });
    }
);


export default axiosInstance;